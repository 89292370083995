import { Container } from "react-bootstrap";
import { useEffect } from "react";

const AccountNotFound = () => {
	useEffect(() => {
		document.title = "Account Not Found | Diocese of Lincoln Schools";
	});

	return (
		<>
			<Container className="bg-light text-secondary p-1 text-center">
				<h1 className="text-primary">Account Not Found</h1>
				<Container className="bg-light text-secondary p-1 text-center">
					<p className="lead">
						We’re unable to locate your account. Please contact your school's
						tech liaison to open a ticket with the Ed Tech team. If you're a new
						student or have recently changed your name, your account may need
						manual review. We apologize for the inconvenience and will resolve
						the issue promptly.
					</p>
				</Container>
			</Container>
		</>
	);
};
export default AccountNotFound;
