import { Container, Row, Col, Image, Card } from "react-bootstrap";
import SubNav from "../../components/SubNav";
import H1 from "../../assets/img/MFA/MFA_H1_Large_Transparent.png";
import H2 from "../../assets/img/MFA/MFA_H1_Transparent_Light.png";
import { useEffect } from "react";

const MFAHardware = () => {
	useEffect(() => {
		document.title =
			"Hardware Authenticator Setup Instructions | Diocese of Lincoln Schools";
	});

	return (
		<>
			<SubNav />
			<Container className="bg-light text-secondary text-center p-1">
				<h1 className="text-primary">Hardware Token Instructions</h1>
				<Col className="col-lg-8 mx-auto lead">
					<p className="lead">
						A hardware token is a device that looks like a keyfob with a small
						screen that can display a one-time password (OTP) every 30 seconds.
					</p>
					<Image
						src={H1}
						className="p-1 mx-lg-auto img-fluid"
						alt="Picture of Hardware Token"
					></Image>
				</Col>
			</Container>
			<Container className="p-2">
				<Row className="align-items-center d-flex flex-column g-2 py-1">
					<Col className="col-lg-8">
						<Card className="p-2 card-cover h-100 overflow-hidden text-black bg-light shadow-lg">
							<Card.Title className="pt-2 mt-2 display-6 lh-1 fw-bold">
								Setup
							</Card.Title>
							<Card.Body className="d-flex flex-column h-100 p-3 text-black text-shadow-1">
								<p className="lead">
									Congratulations, if you were issued a hardware OTP token it is
									already setup and ready to use!
								</p>
							</Card.Body>
						</Card>
					</Col>
				</Row>
				<Row className="align-items-center d-flex flex-column g-2 py-1">
					<Col className="col-lg-8">
						<Card className="p-2 card-cover h-100 overflow-hidden text-black bg-light shadow-lg">
							<Card.Title className="pt-2 mt-2 display-6 lh-1 fw-bold">
								How to Use
							</Card.Title>
							<Card.Body className="d-flex flex-column h-100 p-3 text-black text-shadow-1">
								<p className="lead">
									With multi-factor authentication you will need your email
									address, password, and a code. When asked for a code, press
									the button on your token and a six-digit code will appear.
									Input the code displayed on your token into the device where
									you started to login.
								</p>
								<p className="lead">
									Example: While setting up Email on a new iPad you are asked to
									login. You enter your email address and password, and then are
									asked to enter a code. Locate your token and press the power
									button. Next, type the code displayed by the token into the
									iPad.
								</p>
								<Image
									src={H2}
									className="p-1 mx-lg-auto img-fluid"
								></Image>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};
export default MFAHardware;
