import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Enrollment from "./pages/Enrollment/Enrollment";
import Returning from "./pages/Enrollment/Returning";
import Student from "./pages/Enrollment/Student";
import NewParentPortal from "./pages/Enrollment/NewParentPortal";
import AddParentPortal from "./pages/Enrollment/AddParentPortal";
import PreEnroll from "./pages/Enrollment/PreEnroll";
import New from "./pages/Enrollment/New";
import Lms from "./pages/LMS/Lms";
import MobileApp from "./pages/LMS/MobileApp";
import ParentAccount from "./pages/LMS/ParentAccount";
import MFA from "./pages/MFA/MFA";
import MFAAuthSetup from "./pages/MFA/MFAAuthSetup";
import MFAHardware from "./pages/MFA/MFAHardware";
import MFAHelp from "./pages/MFA/MFAHELP";
import MFAHelpBackup from "./pages/MFA/MFAHELP_BACKUP";
import MFAHelpLockout from "./pages/MFA/MFAHELP_LOCKOUT";
import MFAHelpFaq from "./pages/MFA/MFAHELP_FAQ";
import MFAHelpToken from "./pages/MFA/MFAHELP_TOKEN";
import Notfound from "./pages/Notfound";
import GlobalNav from "./components/GlobalNav";
import GlobalFooter from "./components/GlobalFooter";
import Links from "./pages/Links/links";
import AccountNotFound from "./pages/LMS/AccountNotFound";

function App() {
	return (
		<div className="bg-light d-flex flex-column min-vh-100">
			<GlobalNav />
			<Routes>
				<Route
					path="/"
					element={<Home />}
				/>
				<Route path="/lms">
					<Route
						index
						element={<Lms />}
					/>
					<Route
						path="mobile_app_setup"
						element={<MobileApp />}
					/>
					<Route
						path="mobile_app_setup.html"
						element={<MobileApp />}
					/>
					<Route
						path="parent_account_setup"
						element={<ParentAccount />}
					/>
					<Route
						path="parent_account_setup.html"
						element={<ParentAccount />}
					/>
					<Route
						path="account_not_found"
						element={<AccountNotFound />}
					/>
				</Route>
				<Route path="/enrollment">
					<Route
						index
						element={<Enrollment />}
					/>
					<Route
						path="returning_instructions"
						element={<Returning />}
					/>
					<Route
						path="returning_instructions.html"
						element={<Returning />}
					/>
					<Route
						path="new_student_instructions"
						element={<New />}
					/>
					<Route
						path="new_student_instructions.html"
						element={<New />}
					/>
					<Route
						path="student_instructions"
						element={<Student />}
					/>
					<Route
						path="student_instructions.html"
						element={<Student />}
					/>
					<Route
						path="pre_enroll_instructions"
						element={<PreEnroll />}
					/>
					<Route
						path="new_parent_portal_setup"
						element={<NewParentPortal />}
					/>
					<Route
						path="add_parent_portal_setup"
						element={<AddParentPortal />}
					/>
				</Route>
				<Route path="/mfa">
					<Route
						index
						element={<MFA />}
					/>
					<Route
						path="mfa_authsetup"
						element={<MFAAuthSetup />}
					/>
					<Route
						path="mfa_hardware"
						element={<MFAHardware />}
					/>
					<Route path="mfahelp">
						<Route
							index
							element={<MFAHelp />}
						/>
						<Route
							path="mfahelp_backup"
							element={<MFAHelpBackup />}
						/>
						<Route
							path="mfahelp_lockout"
							element={<MFAHelpLockout />}
						/>
						<Route
							path="mfahelp_faq"
							element={<MFAHelpFaq />}
						/>
						<Route
							path="mfahelp_token"
							element={<MFAHelpToken />}
						></Route>
					</Route>
				</Route>
				<Route path="/links">
					<Route
						index
						element={<Links />}
					/>
				</Route>
				<Route
					path="*"
					element={<Notfound />}
				/>
			</Routes>
			<GlobalFooter />
		</div>
	);
}

export default App;
